<template>
  <template v-if="product.PriceInPoints && product.PriceInPoints.Amount > 0">
    <span
      v-if="product.PriceInPoints.Amount === product.PriceInPoints.WithDiscount"
      :class="priceClassName"
    >
      <common-currency
        show-points
        :amount="product.PriceInPoints.Amount * productCount"
        data-test-id="product-points-price"
      />
    </span>

    <span
      v-if="product.PriceInPoints.Amount !== product.PriceInPoints.WithDiscount"
      class="v-typography-text-6"
    >
      <common-currency
        show-points
        strikethrough
        :amount="product.PriceInPoints.Amount * productCount"
        data-test-id="product-points-price-old"
      />
    </span>
    <span
      v-if="product.PriceInPoints.Amount !== product.PriceInPoints.WithDiscount"
      :class="priceClassName"
    >
      <common-currency
        show-points
        :amount="product.PriceInPoints.WithDiscount * productCount"
        data-test-id="product-points-price-new"
      />
    </span>
  </template>
  <template v-else>
    <span
      v-if="product.Price.Amount === product.Price.WithDiscount"
      :class="priceClassName"
    >
      <common-currency
        :amount="product.Price.Amount * productCount"
        data-test-id="product-price"
      />
    </span>

    <span
      v-if="product.Price.Amount !== product.Price.WithDiscount"
      class="v-typography-text-6"
    >
      <common-currency
        strikethrough
        :amount="product.Price.Amount * productCount"
        data-test-id="product-price-old"
      />
    </span>
    <span
      v-if="product.Price.Amount !== product.Price.WithDiscount"
      :class="priceClassName"
    >
      <common-currency
        :amount="product.Price.WithDiscount * productCount"
        data-test-id="product-price-new"
      />
    </span>
  </template>
</template>

<script setup lang="ts">
import type { CartItem } from '~types/clientStore'

const { calcWithCount, product } = defineProps<{
  priceClassName: string
  product: CartItem
  calcWithCount?: boolean
}>()

const productCount = computed(() => (calcWithCount ? product.Count : 1))
</script>
